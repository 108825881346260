import React from "react";
import Applogic from "../../app/modules/AppLogic";
import MUIButton from "../../components/MaterialUiComponents/MUIButton";
import MUIButtonGroup from "../../components/MaterialUiComponents/MUIButtonGroup";
import MUICheckBoxes from "../../components/MaterialUiComponents/MUICheckBox";
import MUIActionButton from "../../components/MaterialUiComponents/MUIActionButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MUIRadioButton from "../../components/MaterialUiComponents/MUIRadioButton";
import MUIRating from "../../components/MaterialUiComponents/MUIRating";
import MUISlider from "../../components/MaterialUiComponents/MUISlider";
import MUISwitch from "../../components/MaterialUiComponents/MUISwitch";
import MUIFormInput from "../../components/MaterialUiComponents/MUIFormInput";
import MUISelect from "./MUISelect";
import MUIToggleButton from "./MUIToggleButton";
import MUIAvatar from "./MUIAvatar";
import MUIList from "./MUIList";
import {
  Avatars,
  ButtonGroup,
  Buttons,
  Checkboxes,
  floatingaActionAutton,
  List,
  RadioButtons,
  Ratings,
  Select,
  Sliders,
  Switches,
  TextFields,
  Toggle,
} from "../../utils/string";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Item } from "./IndexStyle";
// all matirial ui component called here

function MaterialUiComponents() {
  return (
    <>
      <Box>
        <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Lambda
              </Typography>
              <Applogic />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Buttons
              </Typography>
              <Typography component="p" align="left">
                {Buttons}
              </Typography>
              <Divider />
              <div id="button">
                <MUIButton />
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                ButtonGroup
              </Typography>
              <Typography component="p" align="left">
                {ButtonGroup}
              </Typography>
              <Divider />
              <div id="buttonGroup">
                <MUIButtonGroup />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Checkboxes
              </Typography>

              <Typography component="p" align="left">
                {Checkboxes}
              </Typography>
              <Divider />
              <div className="item-contain" id="checkbox">
                <MUICheckBoxes />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Floating Action Button
              </Typography>
              <Typography component="p" align="left">
                {floatingaActionAutton}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUIActionButton />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                RadioButtons
              </Typography>
              <Typography component="p" align="left">
                {RadioButtons}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUIRadioButton />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Ratings
              </Typography>
              <Typography component="p" align="left">
                {Ratings}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUIRating />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Sliders
              </Typography>
              <Typography component="p" align="left">
                {Sliders}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUISlider />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Switches
              </Typography>
              <Typography component="p" align="left">
                {Switches}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUISwitch />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                TextFields
              </Typography>
              <Typography component="p" align="left">
                {TextFields}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUIFormInput />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Text Select
              </Typography>
              <Typography component="p" align="left">
                {Select}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUISelect />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Toggle
              </Typography>
              <Typography component="p" align="left">
                {Toggle}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUIToggleButton />
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                Avatars
              </Typography>
              <Typography component="p" align="left">
                {Avatars}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUIAvatar />
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" align="left">
                List
              </Typography>
              <Typography component="p" align="left">
                {List}
              </Typography>
              <Divider />
              <div className="item-contain" id="actionbutton">
                <MUIList />
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default MaterialUiComponents;
