import React from 'react'

function AboutUs() {
    return (
        <div>
            About us
        </div>
    )
}

export default AboutUs
