
import React from 'react'
// import { Logo } from '../utils/ImageString'
import {  WelcomeText, WelcomeTitle } from '../utils/string'

function Welcome() {
    return (
        <>
            <h1 className="main-h1">
              {WelcomeTitle}
              </h1>
            <p>
            {WelcomeText}
            </p>
            <br />
          </>
    )
}

export default Welcome
