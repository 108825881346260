import Home from "../pages/Home";
import { UserProvider} from '../context/AuthContext'
function App() {
  return (
    <div className="App">
      <UserProvider>
      <Home />
      </UserProvider>
    </div>
  );
}

export default App;
