import React, { useContext, useEffect, useState } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import awsconfig from "./../../aws-exports";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import IconButton from '@mui/material/IconButton';
import { Button } from "@mui/material";
import { UserDispatchContext } from "../../context/AuthContext";

Amplify.configure(awsconfig);

function App({ mobileView }) {
  const [user, setUser] = useState(null);

  const setUserDetails = useContext(UserDispatchContext);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
     
      switch (event) {
        case "signIn":
          setUserDetails(data)
          
          break;
        case "cognitoHostedUI":
          getUser().then((userData) => {
            setUser(userData);
            setUserDetails(userData);
          });
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
    
    getUser().then((userData) => {setUser(userData)
      setUserDetails(userData);});
  },[setUserDetails] );

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }
  return (
    <div className="menu-mobile" >
      {user ? (
        <>
        {mobileView? <IconButton onClick={() => Auth.signOut()}>  <LogoutIcon  title='logout' /></IconButton>:
         <Button variant="contained" onClick={() => Auth.signOut()}>
            Sign Out
          </Button>}
        </>
      ) : (
        <>
          {mobileView?  <IconButton onClick={() => Auth.federatedSignIn()}><LoginIcon  /></IconButton>:
            <Button variant="contained" onClick={() => Auth.federatedSignIn()}>
              Sign In
            </Button>}
        </>
      )}
    </div>
  );
}

export default App;
