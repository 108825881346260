import * as React from "react";
import { useState, useEffect } from "react";
//router import
import { Link, Outlet } from "react-router-dom";
// mui component imported from mui library
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import SearchIcon from "@mui/icons-material/Search";
import MUIMenuBar from "../MaterialUiComponents/MUIMenuBar";
// import Button from "@mui/material/Button";
// import CloseIcon from "@mui/icons-material/Close";
// material ui style import
import { AppBar, Main, DrawerHeader } from "./LayoutComponentStyle";
//header menu import
import { headerMenu } from "../../utils/MenuTitleAndRoute";
// string import
import Welcome from "../../pages/Welcome";
import { Logo } from "../../utils/ImageString";
import { LogoAlt } from "../../utils/string";
import AuthStateApp from "../../app/modules/AuthStateApp";
import { UserContext } from "../../context/AuthContext";
import { useContext } from "react";

export default function LayoutComponent({ theme }) {
  const userDetails = useContext(UserContext);
  // const [search, setSearch] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    //  checking device and displaying desktop or mobile view

    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Header start here -------------------------------> */}

      <AppBar>
        <Toolbar>
          <DrawerHeader className="sidebar-header ">
            <img src={Logo} alt={LogoAlt} title={LogoAlt} />
          </DrawerHeader>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          ></Typography>
          <Box sx={{ flexGrow: 1 }} />

          {/* header menu listed here , you can update menu name and route from file - /utils/MenuTitleAndRoute */}

          {userDetails ? (
            <>
              {mobileView ? null : (
                <ul className="header-menu">
                  {headerMenu.map((menuItem, index) => (
                    <li key={index}>
                      <Link to={menuItem.link}>{menuItem.name}</Link>
                    </li>
                  ))}
                </ul>
              )}
              {/* {search ? (
                <input
                  type="text"
                  placeholder="Search"
                  className="search-box"
                />
              ) : null} */}
              {/* <Button
                variant="outlined"
                onClick={() => setSearch(search ? false : true)}
              >
                {!search ? <SearchIcon /> : <CloseIcon />}
              </Button> */}
              <Box className="header-menu">
                <MUIMenuBar mobileView={mobileView} auth={userDetails} />
              </Box>
            </>
          ) : null}
          <Box className="header-menu">
            <AuthStateApp mobileView={mobileView} />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main content will render here -------------------------> */}

      <Main className="main-content">
        {userDetails ? (
          <div className="main-inner">
            {/* child component will render here */}
            <Outlet />
          </div>
        ) : (
          // welcome page rendered when user is not logged
          <Welcome />
        )}
      </Main>
    </Box>
  );
}
