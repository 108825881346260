import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { exampleLambda } from '../../graphql/queries';
import Plot from 'react-plotly.js';

// Simple query
class AppLogic extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lambdaData: []
    };
  }

  componentDidMount() {
    this.fetchLambdaData();
  }

  async fetchLambdaData() {
    try {
      let sampleName = 'Some Input data';
      const api_result = await API.graphql(graphqlOperation(exampleLambda, { sampleName: sampleName }))
     
      let result = JSON.parse(JSON.parse(api_result.data.exampleLambda).body);

      
      let x = [].concat(...result.data).map(({x})=>x);
      let y = [].concat(...result.data).map(({y})=>y);
      this.setState({ lambdaData: {request: result.request,x:x,y:y } });
    } catch (err) {
      console.log('error: ', err)
    }
  }
  render() {
    return (
      <>
       <div className="chart-main"> 
          <Plot
            data={[
              {
                x: this.state.lambdaData.x,
                y: this.state.lambdaData.y,
                type: 'scatter',
                mode: 'lines+markers',

                marker: { color: 'red' },
              }
            ]}
            layout={{ width: "auto", height: "auto", title:  this.state.lambdaData.request === undefined ? 'requesting..' : `${this.state.lambdaData.request.sampleName}`} }
          />
        </div>
      </>
    )
  }


};

export default AppLogic;