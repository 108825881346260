import React from 'react'

function DataAnalyze() {
    return (
        <div>
            Dataanalyze
        </div>
    )
}

export default DataAnalyze
