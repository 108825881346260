import LayoutComponent from "../components/LayoutComponents/LayoutComponent";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../components/FooterComponent/Footer";
import MaterialUiComponents from "../components/MaterialUiComponents";
import AboutUs from "./AboutUs";
import Blog from "./Blog";
import DataAnalyze from "./DataAnalyze";
import ContactUS from "./ContactUS";
import NotFound from "./NotFound";

// main homepage loading layout

// you can coustmize all component theme from here
const theme = createTheme({
  palette: {
    primary: {
      main: "#0099f4",
    },
  },
  typography: {
    fontFamily: ["Fira Sans"].join(","),
  },
});
function Home() {
  return (
    <div className="home">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LayoutComponent />}>
              <Route index element={<MaterialUiComponents />} />
              <Route path="aboutus" element={<AboutUs />} />
              <Route path="blog" element={<Blog />} />
              <Route path="dataanalyze" element={<DataAnalyze />} />
              <Route path="contactus" element={<ContactUS />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default Home;
