import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";

import Divider from "@mui/material/Divider";
import { Auth } from "aws-amplify";
import { headerMenu } from "../../utils/MenuTitleAndRoute";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/AuthContext";
import { useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
export default function MUIMenuBar({ mobileView, auth }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userDetails = useContext(UserContext);
  const handleClick = (event) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      {!mobileView ? (
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
        >
          <span> {userDetails?.attributes?.name ? userDetails.attributes.name : userDetails?.username}</span>
        </Button>
      ) : (
        <>
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <span className="menu-mobile">
              <MenuIcon />
            </span>

          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {Auth ? (
              <span onClick={handleClose} className="username">
                {userDetails?.UserAttributes}
              </span>
            ) : null}
            <Divider />
            {mobileView ? (
              <ul className="header-menu-mobile">
                {headerMenu.map((menuItem, index) => (
                  <li key={index}>
                    <Link to={menuItem.link}>{menuItem.name}</Link>
                  </li>
                ))}
                <li onClick={handleClose}> </li>
              </ul>
            ) : null}
          </Menu>
        </>
      )}
    </div>
  );
}

